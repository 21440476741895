import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
// import SolanaWallets from 'solana-wallets-vue'
import { initWallet } from 'solana-wallets-vue'

// You can either import the default styles or create your own.
import 'solana-wallets-vue/styles.css'
import './registerServiceWorker'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from './router'
import appStore, { registerStore } from './store'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TrustWalletAdapter,
  TokenPocketWalletAdapter
} from '@solana/wallet-adapter-wallets'
const pinia = createPinia()
const walletOptions = {
  wallets: [
    new PhantomWalletAdapter(),
    new TrustWalletAdapter(),
    new SolflareWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
    new TokenPocketWalletAdapter()
  ],
  autoConnect: true
}
initWallet(walletOptions)
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(pinia).use(ElementPlus).use(router)
registerStore()

router.isReady().then(() => app.mount('#app'))
