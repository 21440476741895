import { __awaiter, __generator } from "tslib";
/* eslint-disable */
import axios from 'axios';
import { RESERVE_ONLINE_DATA } from './value';
import localOnlineReserveList from './onlineReserve.json';
export function getReserveList() {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get(RESERVE_ONLINE_DATA)];
                case 1:
                    response = _a.sent();
                    console.log('response.data', response.data);
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [2 /*return*/, localOnlineReserveList];
                case 3: return [2 /*return*/];
            }
        });
    });
}
