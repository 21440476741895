import { __awaiter, __generator, __spreadArray } from "tslib";
import * as web3 from "@solana/web3.js";
import Dotenv from "dotenv";
import BigNumber from 'bignumber.js';
import { initWallet, useWallet, useAnchorWallet } from 'solana-wallets-vue';
import * as token from '@solana/spl-token';
import * as anchor from "@project-serum/anchor";
import * as pythTool from "../utils/pyth/index";
import { ElMessage } from 'element-plus';
import { getReserveList } from './online';
import { confetti } from '@tsparticles/confetti';
import { PriceServiceConnection } from "@pythnetwork/price-service-client";
import { PythSolanaReceiver, } from "@pythnetwork/pyth-solana-receiver";
import { PublicKey } from '@solana/web3.js';
import { integerToDecimal, decimalToInteger, } from '../utils/convertors';
// import { commonTool } from '../utils/common'
import { NATIVE_MINT, createAssociatedTokenAccountIdempotentInstruction, createSyncNativeInstruction, createCloseAccountInstruction, getAssociatedTokenAddressSync, wrappedI80F48toBigNumber } from '../utils/common';
import idl from './nxlend.json';
import { programLendingIdStr, marketAccKey, HOURS_PER_YEAR, SOL_WRAP_FACTOR } from './value';
import appStore from '../store';
initWallet({ autoConnect: true });
var PYTH_PRICE_CONF_INTERVALS = new BigNumber(2.12);
var _a = useWallet(), sendTransaction = _a.sendTransaction, signTransaction = _a.signTransaction, signAllTransactions = _a.signAllTransactions;
Dotenv.config();
var programLendingId = new web3.PublicKey(programLendingIdStr);
var marketAcc = new web3.PublicKey(marketAccKey);
// main
var mainConnection = new web3.Connection("https://wispy-winter-silence.solana-mainnet.quiknode.pro/9415245208bc5e7c5209ba52e2c853d060504be4/", "confirmed");
// const connection = new web3.Connection("https://devnet.helius-rpc.com/?api-key=72382ec6-c35a-480c-b71e-af3f7c338a8d", "confirmed");
// test
var connection = new web3.Connection("https://solana-devnet.g.alchemy.com/v2/fotOSWk_cY2zBnS47VTkTG3VUOcKS9ii", "confirmed");
var lookUpTable = new web3.PublicKey('5pEaz66NQra8hrjXSBnW76sBj8xS9rudWZP8RR8rCjqT');
// const connection = new web3.Connection(node_rpc, "confirmed");
// const lookUpTable = new web3.PublicKey(look_up_address)
// const connection = new web3.Connection(web3.clusterApiUrl("devnet"), "confirmed");
export function delay(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
export function createNxlendAccount(needNew, noWallet) {
    if (needNew === void 0) { needNew = true; }
    return __awaiter(this, void 0, void 0, function () {
        var publicKey, userAccSeed, _a, userAcc, userAccBump, wallet, program, nxlendAccData, error_1, ix, Ixs, data, signature;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    publicKey = useWallet().publicKey;
                    if (!(!publicKey.value && !noWallet)) return [3 /*break*/, 3];
                    return [4 /*yield*/, delay(200)];
                case 1:
                    _b.sent();
                    return [4 /*yield*/, createNxlendAccount(needNew, 1)];
                case 2: return [2 /*return*/, _b.sent()];
                case 3:
                    if (!publicKey.value && noWallet) {
                        return [2 /*return*/, null];
                    }
                    userAccSeed = new anchor.BN(50);
                    _a = web3.PublicKey.findProgramAddressSync([marketAcc.toBuffer(), publicKey.value.toBuffer(), userAccSeed.toArrayLike(Buffer, "le", 8)], programLendingId), userAcc = _a[0], userAccBump = _a[1];
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    _b.label = 4;
                case 4:
                    _b.trys.push([4, 6, , 7]);
                    return [4 /*yield*/, program.account.nxlendAccount.fetch(userAcc)];
                case 5:
                    nxlendAccData = _b.sent();
                    return [2 /*return*/, {
                            key: userAcc.toBase58(),
                            data: nxlendAccData
                        }];
                case 6:
                    error_1 = _b.sent();
                    console.log('nxlendAccount.fetch', error_1);
                    return [3 /*break*/, 7];
                case 7:
                    if (!needNew)
                        return [2 /*return*/, null
                            //create account
                        ];
                    return [4 /*yield*/, program.methods.createUserAccount(userAccSeed).accounts({
                            nxlendMarket: marketAcc,
                            nxlendAccount: userAcc,
                            owner: publicKey.value,
                            feePayer: publicKey.value,
                            systemProgram: web3.SystemProgram.programId
                        })
                            .signers([])
                            .instruction()];
                case 8:
                    ix = _b.sent();
                    Ixs = [ix];
                    data = {
                        type: 'Creating NxLend Account',
                        text: "Creating NxLend Account",
                        typed: 'Created NxLend account successfully',
                        textd: "Created NxLend account successfully",
                    };
                    return [4 /*yield*/, nxSendTransaction(Ixs, data)];
                case 9:
                    signature = _b.sent();
                    return [4 /*yield*/, createNxlendAccount()];
                case 10: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
function getNativeBalance(address) {
    return __awaiter(this, void 0, void 0, function () {
        var pbk, amount, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pbk = new web3.PublicKey(address);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, connection.getBalance(pbk)];
                case 2:
                    amount = _a.sent();
                    return [2 /*return*/, integerToDecimal(amount, 9)];
                case 3:
                    error_2 = _a.sent();
                    console.log('connection.getBalance', error_2);
                    return [2 /*return*/, 0];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getReserveListOwner(getBalanceFlag, clearBalanceFlag) {
    if (getBalanceFlag === void 0) { getBalanceFlag = true; }
    if (clearBalanceFlag === void 0) { clearBalanceFlag = false; }
    return __awaiter(this, void 0, void 0, function () {
        var onLineReserveList, sliderList, reserveList, publicKey, autoRepay, wallet, program, reserves, nxlendReserveDatas, i, _loop_1, pythOracleList, price_data, risk_level, nxlend_acc, error_3, nxlendAccData, self_risk, mybalance, balanceActive_1, j, _loop_2, j, r, amount, balance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(appStore.reserve.state.onlineReserveList.length > 0)) return [3 /*break*/, 1];
                    onLineReserveList = JSON.parse(JSON.stringify(appStore.reserve.state.onlineReserveList));
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, getReserveList()];
                case 2:
                    onLineReserveList = _a.sent();
                    appStore.reserve.state.onlineReserveList = onLineReserveList;
                    _a.label = 3;
                case 3:
                    sliderList = onLineReserveList.map(function (e) {
                        e.reserve_mint_key = e.tokenMint;
                        e.reserve_key = e.reserveAddress;
                        e.slider = 50;
                        return e;
                    });
                    appStore.profit.state.sliderList = sliderList;
                    appStore.account.state.sliderChange = appStore.account.state.sliderChange + 1;
                    reserveList = onLineReserveList.map(function (e) {
                        //e.token_img = require(e.logoURI) ? require(e.logoURI) : require('@/assets/token/sol.webp')
                        e.token_img = e.logoURI;
                        e.token_name = e.tokenSymbol;
                        e.reserve_mint_key = e.tokenMint;
                        e.reserve_key = e.reserveAddress;
                        e.priceFeedID = e.priceFeedID ? e.priceFeedID : null;
                        e.reserveAta = '',
                            e.total_supply = 0,
                            e.total_borrow = 0,
                            e.supply_apr = 0,
                            e.borrow_apr = 0,
                            e.supply_apy = 0,
                            e.borrow_apy = 0,
                            e.ltv = 0,
                            e.asset_ntoken_ratio = 0,
                            e.debit_ntoken_ratio = 0,
                            e.my_supply = 0,
                            e.my_borrow = 0,
                            e.amount = 0,
                            e.capacity = 0,
                            e.maxBorrow = 0,
                            e.usd_price = 0,
                            e.usd_price_low = 0,
                            e.usd_price_high = 0,
                            e.usd_price_weighted = 0,
                            e.usd_price_weighted_low = 0,
                            e.usd_price_weighted_high = 0,
                            e.decimals = 0,
                            e.asset_ratio = 0,
                            e.asset_liq_ratio = 0,
                            e.debit_set_ratio = 0,
                            e.debit_high_ratio = 0,
                            e.debit_mid_ratio = 0,
                            e.debit_low_ratio = 0,
                            e.debit_liq_ratio = 0,
                            e.type = 1,
                            e.interestSetting = {};
                        return e;
                    });
                    publicKey = useWallet().publicKey;
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    reserves = reserveList.map(function (e) {
                        return new web3.PublicKey(e.reserve_key);
                    });
                    return [4 /*yield*/, getOnchainReserveData(program, reserves)
                        // try {
                        //   nxlendReserveDatas = await program.account.reserve.fetchMultiple(reserves)
                        // } catch (error) {
                        //   console.log('fetchMultiple reserve datas',error)
                        //   return
                        // }
                    ];
                case 4:
                    nxlendReserveDatas = _a.sent();
                    // try {
                    //   nxlendReserveDatas = await program.account.reserve.fetchMultiple(reserves)
                    // } catch (error) {
                    //   console.log('fetchMultiple reserve datas',error)
                    //   return
                    // }
                    console.log('nxlendReserveDatas', nxlendReserveDatas);
                    i = 0;
                    _loop_1 = function () {
                        var tokenMint = new web3.PublicKey(reserveList[i].reserve_mint_key);
                        var nxlendReserveData;
                        nxlendReserveDatas.forEach(function (e) {
                            if (e.tokenMint.equals(tokenMint)) {
                                nxlendReserveData = e;
                            }
                        });
                        if (!nxlendReserveData) {
                            i = i + 1;
                            return "continue";
                        }
                        reserveList[i].type = 0;
                        if (JSON.stringify(nxlendReserveData.setting.reserveType).indexOf('collateral') > -1) {
                            reserveList[i].type = 1;
                        }
                        if (JSON.stringify(nxlendReserveData.setting.reserveType).indexOf('noCollaAddup') > -1) {
                            reserveList[i].type = 2;
                        }
                        if (reserveList[i].type === 0) {
                            ElMessage({
                                message: 'error collateral type',
                                type: 'warning',
                            });
                        }
                        if (JSON.stringify(nxlendReserveData.setting.oracleType).indexOf('pythEma') > -1) {
                            reserveList[i].oracleType = 'pythV1';
                        }
                        if (JSON.stringify(nxlendReserveData.setting.oracleType).indexOf('pythV2') > -1) {
                            reserveList[i].oracleType = 'pythV2';
                        }
                        reserveList[i].pythOracle = nxlendReserveData.setting.oracleKeys[0].toBase58();
                        reserveList[i].capacity = integerToDecimal(nxlendReserveData.setting.capacity, nxlendReserveData.tokenDecimals);
                        reserveList[i].maxBorrow = integerToDecimal(nxlendReserveData.setting.maxBorrowable, nxlendReserveData.tokenDecimals);
                        reserveList[i].reserveAta = nxlendReserveData.tokenInfo.tokenAccount;
                        reserveList[i].asset_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueRatio).toNumber();
                        reserveList[i].asset_liq_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueLiqRatio).toNumber();
                        reserveList[i].debit_low_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioLowRisk).toNumber();
                        reserveList[i].debit_mid_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioMidRisk).toNumber();
                        reserveList[i].debit_high_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioHighRisk).toNumber();
                        reserveList[i].debit_liq_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueLiqRatio).toNumber();
                        var totalAssetQuantity = getTotalAssetQuantity(wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.reserveAssetNtokenAmount), wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.assetNtokenRatio));
                        var totalLiabilityQuantity = getTotalLiabilityQuantity(wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.reserveDebtNtokenAmount), wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.debtNtokenRatio));
                        reserveList[i].decimals = nxlendReserveData.tokenDecimals;
                        // const ltv = new BigNumber(1).div(wrappedI80F48toBigNumber(debitValueRatioRisk)).times(wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueRatio)).times(100).toFixed(2)
                        // reserveList[i].ltv = ltv
                        var interestSetting = {
                            rateChangeUr1: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.rateChangeUr1).toString(),
                            rateChangeUr2: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.rateChangeUr2).toString(),
                            irUr1: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.irUr1).toString(),
                            irUr2: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.irUr2).toString(),
                            maxIr: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.maxIr).toString(),
                            treasuryBaseApr: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.treasuryBaseApr).toString(),
                            treasuryAdditionRatio: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.treasuryAdditionRatio).toString(),
                            marketFeeBase: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.marketFeeBase).toString(),
                            marketAdditionRatio: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.marketAdditionRatio).toString()
                        };
                        reserveList[i].interestSetting = interestSetting;
                        reserveList[i].asset_ntoken_ratio = nxlendReserveData.creditDebit.assetNtokenRatio;
                        reserveList[i].debit_ntoken_ratio = nxlendReserveData.creditDebit.debtNtokenRatio;
                        reserveList[i].total_supply = integerToDecimal(totalAssetQuantity.toFixed(0), nxlendReserveData.tokenDecimals);
                        reserveList[i].total_borrow = integerToDecimal(totalLiabilityQuantity.toFixed(0), nxlendReserveData.tokenDecimals);
                        var apy = getApyNew(reserveList[i].total_supply, reserveList[i].total_borrow, reserveList[i].interestSetting);
                        reserveList[i].supply_apr = Number.isNaN(apy.supplyAPR.toNumber()) ? 0 : new BigNumber(apy.supplyAPR).multipliedBy(100).toFixed(2);
                        reserveList[i].supply_apy = Number.isNaN(apy.supplyAPY) ? 0 : new BigNumber(apy.supplyAPY).multipliedBy(100).toFixed(2);
                        reserveList[i].borrow_apr = new BigNumber(apy.borrowAPR).multipliedBy(100).toFixed(2);
                        reserveList[i].borrow_apy = new BigNumber(apy.borrowAPY).multipliedBy(100).toFixed(2);
                        i = i + 1;
                    };
                    while (i < reserveList.length) {
                        _loop_1();
                    }
                    console.log('reserveList', reserveList);
                    pythOracleList = reserveList.map(function (e) {
                        return { type: e.oracleType, pythOracle: new web3.PublicKey(e.pythOracle), reserveAddress: e.reserve_key, priceFeed: e.priceFeedID };
                    });
                    return [4 /*yield*/, getOnchainPriceData(pythOracleList)
                        // try {
                        //   price_data = await connection.getMultipleAccountsInfo(pythOracleList)
                        // } catch (error) {
                        //   console.log('getMultipleAccountsInfo pythOracleList',error)
                        // }
                    ];
                case 5:
                    price_data = _a.sent();
                    // try {
                    //   price_data = await connection.getMultipleAccountsInfo(pythOracleList)
                    // } catch (error) {
                    //   console.log('getMultipleAccountsInfo pythOracleList',error)
                    // }
                    reserveList.forEach(function (r) {
                        price_data.forEach(function (p) {
                            if (p.reserveAddress === r.reserve_key) {
                                var priceRes = p.priceData;
                                r.usd_price = priceRes.priceRealtime.price;
                                r.usd_price_low = priceRes.priceRealtime.lowestPrice;
                                r.usd_price_high = priceRes.priceRealtime.highestPrice;
                                r.usd_price_weighted = priceRes.priceWeighted.price;
                                r.usd_price_weighted_low = priceRes.priceWeighted.lowestPrice;
                                r.usd_price_weighted_high = priceRes.priceWeighted.highestPrice;
                            }
                        });
                    });
                    risk_level = 1;
                    _a.label = 6;
                case 6:
                    _a.trys.push([6, 8, , 9]);
                    return [4 /*yield*/, createNxlendAccount(false)];
                case 7:
                    nxlend_acc = _a.sent();
                    return [3 /*break*/, 9];
                case 8:
                    error_3 = _a.sent();
                    console.log('createNxlendAccount', error_3);
                    return [3 /*break*/, 9];
                case 9:
                    if (nxlend_acc) {
                        nxlendAccData = nxlend_acc.data;
                        // try {
                        //   nxlendAccData = await program.account.nxlendAccount.fetch(nxlend_acc_pbk)
                        // } catch (error) {
                        //   console.log('nxlendAccount.fetch',error)
                        // }
                        console.log('nxlendAccData', nxlendAccData);
                        autoRepay = wrappedI80F48toBigNumber(nxlendAccData.repayLtv).toString();
                        self_risk = JSON.stringify(nxlendAccData.riskPreference);
                        if (self_risk.indexOf('low') > -1) {
                            risk_level = 0;
                        }
                        if (self_risk.indexOf('middle') > -1) {
                            risk_level = 1;
                        }
                        if (self_risk.indexOf('high') > -1) {
                            risk_level = 2;
                        }
                        mybalance = nxlendAccData.userMarketInfo.userReserves;
                        balanceActive_1 = [];
                        mybalance.forEach(function (e) {
                            if (e.active) {
                                balanceActive_1.push(e);
                            }
                        });
                        balanceActive_1.forEach(function (e) {
                            reserveList.forEach(function (r) {
                                if (e.active && e.reservePk.toBase58() === r.reserve_key) {
                                    r.my_supply = integerToDecimal(getTotalAssetQuantity(wrappedI80F48toBigNumber(e.assetNtokenAmount), wrappedI80F48toBigNumber(r.asset_ntoken_ratio)).toFixed(0), r.decimals);
                                    r.my_borrow = integerToDecimal(getTotalLiabilityQuantity(wrappedI80F48toBigNumber(e.debtNtokenAmount), wrappedI80F48toBigNumber(r.debit_ntoken_ratio)).toFixed(0), r.decimals);
                                }
                            });
                        });
                        reserveList.forEach(function (r) {
                            switch (risk_level) {
                                case 0:
                                    r.debit_set_ratio = r.debit_low_ratio;
                                    break;
                                case 1:
                                    r.debit_set_ratio = r.debit_mid_ratio;
                                    break;
                                case 2:
                                    r.debit_set_ratio = r.debit_high_ratio;
                                    break;
                            }
                        });
                    }
                    else {
                        reserveList.forEach(function (r) {
                            r.my_supply = 0;
                            r.my_borrow = 0;
                            r.debit_set_ratio = r.debit_mid_ratio;
                        });
                    }
                    reserveList.forEach(function (e) {
                        e.ltv = new BigNumber(1).div(e.debit_set_ratio).times(100).toFixed(2);
                    });
                    appStore.reserve.state.reserveList = reserveList;
                    appStore.account.state.riskLevel = risk_level;
                    appStore.account.state.autoRepayLtv = autoRepay;
                    appStore.account.state.reserveChange = appStore.account.state.reserveChange + 1;
                    if (!(publicKey.value && getBalanceFlag)) return [3 /*break*/, 13];
                    j = 0;
                    _loop_2 = function () {
                        var r, amount, user_token_acc_key, error_4, balance;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    r = reserveList[j];
                                    amount = void 0;
                                    if (!(r.reserve_mint_key === NATIVE_MINT.toBase58())) return [3 /*break*/, 2];
                                    return [4 /*yield*/, getNativeBalance(publicKey.value)];
                                case 1:
                                    amount = _b.sent();
                                    return [3 /*break*/, 5];
                                case 2:
                                    _b.trys.push([2, 4, , 5]);
                                    return [4 /*yield*/, createTokenAccount(connection, publicKey.value, new web3.PublicKey(r.reserve_mint_key), publicKey.value, true, false)];
                                case 3:
                                    user_token_acc_key = _b.sent();
                                    amount = user_token_acc_key ? integerToDecimal(user_token_acc_key.amount, r.decimals) : 0;
                                    return [3 /*break*/, 5];
                                case 4:
                                    error_4 = _b.sent();
                                    amount = 0;
                                    return [3 /*break*/, 5];
                                case 5:
                                    balance = {
                                        mintKey: r.reserve_mint_key,
                                        amount: amount
                                    };
                                    if (appStore.account.state.balanceList.length < reserveList.length) {
                                        appStore.account.state.balanceList[j] = balance;
                                    }
                                    else {
                                        appStore.account.state.balanceList.forEach(function (e) {
                                            if (e.mintKey === balance.mintKey) {
                                                e.amount = balance.amount;
                                            }
                                        });
                                    }
                                    appStore.account.state.change = appStore.account.state.change + 1;
                                    j = j + 1;
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _a.label = 10;
                case 10:
                    if (!(j < reserveList.length)) return [3 /*break*/, 12];
                    return [5 /*yield**/, _loop_2()];
                case 11:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 12: return [3 /*break*/, 14];
                case 13:
                    if (clearBalanceFlag) {
                        j = 0;
                        while (j < reserveList.length) {
                            r = reserveList[j];
                            amount = 0;
                            balance = {
                                mintKey: r.reserve_mint_key,
                                amount: amount
                            };
                            appStore.account.state.balanceList.push(balance);
                            appStore.account.state.change = appStore.account.state.change + 1;
                            j = j + 1;
                        }
                    }
                    _a.label = 14;
                case 14:
                    appStore.account.state.change = appStore.account.state.change + 1;
                    appStore.account.state.reserveChange = appStore.account.state.reserveChange + 1;
                    // return reserveList
                    console.log('reserveList', reserveList);
                    return [2 /*return*/];
            }
        });
    });
}
function getOnchainPriceData(priceList) {
    return __awaiter(this, void 0, void 0, function () {
        var priceData, v1list, v2list, priceAccount, res, priceDataList, i, priceData1, priceData_1, item, priceFeedList, v2PriceData, i, priceData_2, item, time_1, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('priceList', priceList);
                    v1list = [];
                    v2list = [];
                    priceList.forEach(function (e) {
                        if (e.type === 'pythV1') {
                            v1list.push(e);
                        }
                        if (e.type === 'pythV2') {
                            v2list.push(e);
                        }
                    });
                    console.log('v1list', v1list);
                    console.log('v2list', v2list);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 7]);
                    priceAccount = v1list.map(function (p) {
                        return p.pythOracle;
                    });
                    console.log('priceAccount', priceAccount);
                    return [4 /*yield*/, connection.getMultipleAccountsInfo(priceAccount)];
                case 2:
                    res = _a.sent();
                    priceDataList = [];
                    for (i = 0; i < v1list.length; i++) {
                        priceData1 = pythTool.parsePriceData(res[i].data);
                        priceData_1 = getPrice(priceData1);
                        item = { type: v1list[i].type, priceData: priceData_1, reserveAddress: v1list[i].reserveAddress, time: priceData_1.time };
                        priceDataList.push(item);
                    }
                    priceFeedList = v2list.map(function (p) {
                        if (p.reserveAddress === 'J7cCxuCXkqvZZMXQbcQaD2Wg1B3vFZpnHSFko9HFRBEy') {
                            return '0x5169491cd7e2a44c98353b779d5eb612e4ac32e073f5cc534303d86307c2f1bc';
                        }
                        else {
                            return p.priceFeed;
                        }
                    });
                    return [4 /*yield*/, getPriceV2(priceFeedList)];
                case 3:
                    v2PriceData = _a.sent();
                    console.log('v2PriceData', v2PriceData);
                    for (i = 0; i < v2list.length; i++) {
                        priceData_2 = v2PriceData && v2PriceData[i];
                        item = { type: v2list[i].type, priceData: priceData_2, reserveAddress: v2list[i].reserveAddress, time: priceData_2.time };
                        priceDataList.push(item);
                    }
                    time_1 = new Date().getTime();
                    priceDataList.forEach(function (p) {
                        var cut = new BigNumber(p.time).times(1000).minus(Number(time_1)).toString();
                        console.log(p.reserveAddress);
                        console.log('cut', cut);
                    });
                    console.log('priceDataList', priceDataList);
                    return [2 /*return*/, priceDataList];
                case 4:
                    error_5 = _a.sent();
                    return [4 /*yield*/, delay(5000)];
                case 5:
                    _a.sent();
                    console.log('getMultipleAccountsInfo pythOracleList', error_5);
                    return [4 /*yield*/, getOnchainPriceData(priceList)];
                case 6:
                    priceData = _a.sent();
                    return [2 /*return*/, priceData];
                case 7: return [2 /*return*/];
            }
        });
    });
}
function getOnchainReserveData(program, reserveList) {
    return __awaiter(this, void 0, void 0, function () {
        var reserveListData, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 5]);
                    return [4 /*yield*/, program.account.reserve.fetchMultiple(reserveList)];
                case 1:
                    reserveListData = _a.sent();
                    return [2 /*return*/, reserveListData];
                case 2:
                    error_6 = _a.sent();
                    console.log('getOnchain reserve Data failed', error_6);
                    return [4 /*yield*/, delay(500)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, getOnchainReserveData(program, reserveList)];
                case 4:
                    reserveListData = _a.sent();
                    return [2 /*return*/, reserveListData];
                case 5: return [2 /*return*/];
            }
        });
    });
}
function getPriceV2($priceFeedList) {
    return __awaiter(this, void 0, void 0, function () {
        var priceServiceConnection, price_data, v2PriceData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    priceServiceConnection = new PriceServiceConnection("https://hermes.pyth.network/", { priceFeedRequestConfig: { binary: true } });
                    return [4 /*yield*/, priceServiceConnection.getLatestPriceFeeds($priceFeedList)];
                case 1:
                    price_data = _a.sent();
                    console.log('price_data', price_data);
                    v2PriceData = price_data === null || price_data === void 0 ? void 0 : price_data.map(function (e) {
                        var data = e.toJson();
                        var pythPriceRealtime = new BigNumber(data.price.price).shiftedBy(data.price.expo);
                        var pythConfidenceRealtime = new BigNumber(data.price.conf).shiftedBy(data.price.expo);
                        var pythLowestPriceRealtime = pythPriceRealtime.minus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
                        var pythHighestPriceRealtime = pythPriceRealtime.plus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
                        var pythPrice = new BigNumber(data.ema_price.price).shiftedBy(data.ema_price.expo);
                        var pythConfInterval = new BigNumber(data.ema_price.conf).shiftedBy(data.ema_price.expo);
                        var pythLowestPrice = pythPrice.minus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
                        var pythHighestPrice = pythPrice.plus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
                        var time = data.price.publish_time;
                        console.log(pythPriceRealtime.toString());
                        console.log(pythPrice.toString());
                        var priceRealtime = {
                            price: pythPriceRealtime,
                            confidence: pythConfidenceRealtime,
                            lowestPrice: pythLowestPriceRealtime,
                            highestPrice: pythHighestPriceRealtime,
                        };
                        var priceWeighted = {
                            price: pythPrice,
                            confidence: pythConfInterval,
                            lowestPrice: pythLowestPrice,
                            highestPrice: pythHighestPrice,
                        };
                        var priceData = {
                            time: time,
                            priceRealtime: priceRealtime,
                            priceWeighted: priceWeighted
                        };
                        return priceData;
                    });
                    return [2 /*return*/, v2PriceData];
            }
        });
    });
}
function getPrice(pythPriceData) {
    var priceData = pythPriceData.price;
    if (priceData === undefined) {
        priceData = pythPriceData.previousPrice;
    }
    var confidenceData = pythPriceData.confidence;
    if (confidenceData === undefined) {
        confidenceData = pythPriceData.previousConfidence;
    }
    var time = pythPriceData.timestamp;
    var pythPriceRealtime = new BigNumber(priceData);
    var pythConfidenceRealtime = new BigNumber(confidenceData);
    var pythLowestPriceRealtime = pythPriceRealtime.minus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
    var pythHighestPriceRealtime = pythPriceRealtime.plus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
    var pythPrice = new BigNumber(pythPriceData.emaPrice.value);
    var pythConfInterval = new BigNumber(pythPriceData.emaConfidence.value);
    var pythLowestPrice = pythPrice.minus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
    var pythHighestPrice = pythPrice.plus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
    return {
        time: time,
        priceRealtime: {
            price: pythPriceRealtime,
            confidence: pythConfidenceRealtime,
            lowestPrice: pythLowestPriceRealtime,
            highestPrice: pythHighestPriceRealtime,
        },
        priceWeighted: {
            price: pythPrice,
            confidence: pythConfInterval,
            lowestPrice: pythLowestPrice,
            highestPrice: pythHighestPrice,
        },
    };
}
export function getApy(supply, borrow, interestSetting) {
    var ONE = new BigNumber(1);
    var supplyBN = new BigNumber(supply);
    var borrowBN = new BigNumber(borrow);
    var use = borrowBN.dividedBy(supplyBN);
    var rateChangeUr1 = wrappedI80F48toBigNumber(interestSetting.rateChangeUr1);
    var rateChangeUr2 = wrappedI80F48toBigNumber(interestSetting.rateChangeUr2);
    var irUr1 = wrappedI80F48toBigNumber(interestSetting.irUr1);
    var irUr2 = wrappedI80F48toBigNumber(interestSetting.irUr2);
    var maxIr = wrappedI80F48toBigNumber(interestSetting.maxIr);
    var treasuryBaseApr = wrappedI80F48toBigNumber(interestSetting.treasuryBaseApr);
    var treasuryAdditionRatio = wrappedI80F48toBigNumber(interestSetting.treasuryAdditionRatio);
    var marketFeeBase = wrappedI80F48toBigNumber(interestSetting.marketFeeBase);
    var marketAdditionRatio = wrappedI80F48toBigNumber(interestSetting.marketAdditionRatio);
    var borrowBaseAPY = new BigNumber(0);
    if (use.lte(rateChangeUr1)) {
        borrowBaseAPY = use.dividedBy(rateChangeUr1).multipliedBy(irUr1);
    }
    if (use.lte(rateChangeUr2) && use.gt(rateChangeUr1)) {
        borrowBaseAPY = (use.minus(rateChangeUr1)).dividedBy(rateChangeUr2.minus(rateChangeUr1)).multipliedBy(irUr2.minus(irUr1)).plus(irUr1);
    }
    if (use.gt(rateChangeUr2)) {
        borrowBaseAPY = (use.minus(rateChangeUr2)).dividedBy(ONE.minus(rateChangeUr2)).multipliedBy(maxIr.minus(irUr2)).plus(irUr2);
    }
    var supplyAPY = (borrowBaseAPY.multipliedBy(use));
    var borrowAPY = (borrowBaseAPY.multipliedBy(treasuryAdditionRatio.plus(marketAdditionRatio).plus(1)).plus(treasuryBaseApr.plus(marketFeeBase)));
    var apy = {
        supplyAPY: supplyAPY,
        borrowAPY: borrowAPY,
    };
    return apy;
}
export function getApyNew(supply, borrow, interestSetting) {
    var ONE = new BigNumber(1);
    var supplyBN = new BigNumber(supply);
    var borrowBN = new BigNumber(borrow);
    var use = borrowBN.dividedBy(supplyBN);
    var rateChangeUr1 = new BigNumber(interestSetting.rateChangeUr1);
    var rateChangeUr2 = new BigNumber(interestSetting.rateChangeUr2);
    var irUr1 = new BigNumber(interestSetting.irUr1);
    var irUr2 = new BigNumber(interestSetting.irUr2);
    var maxIr = new BigNumber(interestSetting.maxIr);
    var treasuryBaseApr = new BigNumber(interestSetting.treasuryBaseApr);
    var treasuryAdditionRatio = new BigNumber(interestSetting.treasuryAdditionRatio);
    var marketFeeBase = new BigNumber(interestSetting.marketFeeBase);
    var marketAdditionRatio = new BigNumber(interestSetting.marketAdditionRatio);
    var borrowBaseAPY = new BigNumber(0);
    if (use.lte(rateChangeUr1)) {
        borrowBaseAPY = use.dividedBy(rateChangeUr1).multipliedBy(irUr1);
    }
    if (use.lte(rateChangeUr2) && use.gt(rateChangeUr1)) {
        borrowBaseAPY = (use.minus(rateChangeUr1)).dividedBy(rateChangeUr2.minus(rateChangeUr1)).multipliedBy(irUr2.minus(irUr1)).plus(irUr1);
    }
    if (use.gt(rateChangeUr2)) {
        borrowBaseAPY = (use.minus(rateChangeUr2)).dividedBy(ONE.minus(rateChangeUr2)).multipliedBy(maxIr.minus(irUr2)).plus(irUr2);
    }
    var supplyAPR = borrowBaseAPY.multipliedBy(use);
    var supplyAPY = aprToApy((borrowBaseAPY.multipliedBy(use)).toNumber());
    var borrowAPR = borrowBaseAPY.multipliedBy(treasuryAdditionRatio.plus(marketAdditionRatio).plus(1)).plus(treasuryBaseApr.plus(marketFeeBase));
    var borrowAPY = aprToApy((borrowBaseAPY.multipliedBy(treasuryAdditionRatio.plus(marketAdditionRatio).plus(1)).plus(treasuryBaseApr.plus(marketFeeBase))).toNumber());
    var apy = {
        supplyAPR: supplyAPR,
        supplyAPY: supplyAPY,
        borrowAPR: borrowAPR,
        borrowAPY: borrowAPY,
    };
    return apy;
}
export function getAprNew(supply, borrow, interestSetting) {
    var ONE = new BigNumber(1);
    var supplyBN = new BigNumber(supply);
    var borrowBN = new BigNumber(borrow);
    var use = borrowBN.dividedBy(supplyBN);
    var rateChangeUr1 = new BigNumber(interestSetting.rateChangeUr1);
    var rateChangeUr2 = new BigNumber(interestSetting.rateChangeUr2);
    var irUr1 = new BigNumber(interestSetting.irUr1);
    var irUr2 = new BigNumber(interestSetting.irUr2);
    var maxIr = new BigNumber(interestSetting.maxIr);
    var treasuryBaseApr = new BigNumber(interestSetting.treasuryBaseApr);
    var treasuryAdditionRatio = new BigNumber(interestSetting.treasuryAdditionRatio);
    var marketFeeBase = new BigNumber(interestSetting.marketFeeBase);
    var marketAdditionRatio = new BigNumber(interestSetting.marketAdditionRatio);
    var borrowBaseAPY = new BigNumber(0);
    if (use.lte(rateChangeUr1)) {
        borrowBaseAPY = use.dividedBy(rateChangeUr1).multipliedBy(irUr1);
    }
    if (use.lte(rateChangeUr2) && use.gt(rateChangeUr1)) {
        borrowBaseAPY = (use.minus(rateChangeUr1)).dividedBy(rateChangeUr2.minus(rateChangeUr1)).multipliedBy(irUr2.minus(irUr1)).plus(irUr1);
    }
    if (use.gt(rateChangeUr2)) {
        borrowBaseAPY = (use.minus(rateChangeUr2)).dividedBy(ONE.minus(rateChangeUr2)).multipliedBy(maxIr.minus(irUr2)).plus(irUr2);
    }
    var supplyAPR = borrowBaseAPY.multipliedBy(use);
    var borrowAPR = borrowBaseAPY.multipliedBy(treasuryAdditionRatio.plus(marketAdditionRatio).plus(1)).plus(treasuryBaseApr.plus(marketFeeBase));
    var apy = {
        supplyAPR: supplyAPR,
        borrowAPR: borrowAPR,
    };
    return apy;
}
export function getAccData(nxlend_acc_key) {
    return __awaiter(this, void 0, void 0, function () {
        var wallet, nxlend_acc_pbk, program, nxlendAccData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    wallet = useAnchorWallet();
                    nxlend_acc_pbk = new web3.PublicKey(nxlend_acc_key);
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    return [4 /*yield*/, program.account.nxlendAccount.fetch(nxlend_acc_pbk)];
                case 1:
                    nxlendAccData = _a.sent();
                    return [2 /*return*/, nxlendAccData];
            }
        });
    });
}
export function checkHealth() {
    return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); });
}
export function lendSetAccountRiskPreference(nxlend_acc, risk_level) {
    return __awaiter(this, void 0, void 0, function () {
        var publicKey, wallet, program, nxlend_acc_pbk, riskParams, ix, Ixs, risk, data, signature;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    publicKey = useWallet().publicKey;
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key);
                    switch (risk_level) {
                        case 0:
                            riskParams = {
                                low: {}
                            };
                            break;
                        case 1:
                            riskParams = {
                                middle: {}
                            };
                            break;
                        case 2:
                            riskParams = {
                                high: {}
                            };
                            break;
                        default:
                            break;
                    }
                    return [4 /*yield*/, program.methods.setAccountRiskPreference(riskParams).accounts({
                            nxlendMarket: marketAcc,
                            nxlendAccount: nxlend_acc_pbk,
                            owner: publicKey.value,
                        })
                            .signers([])
                            .instruction()];
                case 1:
                    ix = _a.sent();
                    Ixs = [ix];
                    switch (risk_level) {
                        case 0:
                            risk = 'Low';
                            break;
                        case 1:
                            risk = 'Medium';
                            break;
                        case 2:
                            risk = 'High';
                            break;
                        default:
                            break;
                    }
                    data = {
                        type: 'Set Risk',
                        text: "Set risk to ".concat(risk),
                        typed: 'Set Risk',
                        textd: "Set risk to ".concat(risk),
                    };
                    return [4 /*yield*/, nxSendTransaction(Ixs, data)];
                case 2:
                    signature = _a.sent();
                    return [2 /*return*/, signature];
            }
        });
    });
}
export function lendingAccountDeposit(amount, nxlend_acc, reserve_str, reserve_mint_str, decimals, reserveData) {
    return __awaiter(this, void 0, void 0, function () {
        var reserve, reserve_mint, _a, liquidity_vault, liquidity_vault_bump, reserve_l_v_g, publicKey, wallet, program, user_token_data, nxlend_acc_pbk, amountInt, deposit_amount, ix, Ixs, data, priceFeeds, signature;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    reserve = new web3.PublicKey(reserve_str);
                    reserve_mint = new web3.PublicKey(reserve_mint_str);
                    _a = web3.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_seed")), reserve.toBuffer()], programLendingId), liquidity_vault = _a[0], liquidity_vault_bump = _a[1];
                    reserve_l_v_g = liquidity_vault;
                    publicKey = useWallet().publicKey;
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    return [4 /*yield*/, makeTokenAccountIx(connection, publicKey.value, reserve_mint, publicKey.value, true)];
                case 1:
                    user_token_data = _b.sent();
                    nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key);
                    amountInt = decimalToInteger(amount, decimals);
                    deposit_amount = new anchor.BN(amountInt);
                    return [4 /*yield*/, program.methods.depositIntoReserve(deposit_amount).accounts({
                            nxlendMarket: marketAcc,
                            nxlendAccount: nxlend_acc_pbk,
                            signer: publicKey.value,
                            reserve: reserve,
                            // signerTokenAccount: user_token_acc_key.address,
                            signerTokenAccount: user_token_data.AssociatedTokenAddress,
                            reserveAssetTokenAccount: reserve_l_v_g,
                            tokenProgram: token.TOKEN_PROGRAM_ID
                        })
                            .signers([])
                            .instruction()];
                case 2:
                    ix = _b.sent();
                    Ixs = reserve_mint_str === NATIVE_MINT.toBase58() ? wrapInstructionForWSol(publicKey.value, ix, amountInt) : [ix];
                    data = {
                        type: 'Depositing',
                        text: "Depositing ".concat(amount, " ").concat(reserveData.token_name, " into the market"),
                        typed: 'Depositing',
                        textd: "Depositing ".concat(amount, " ").concat(reserveData.token_name, " into the market"),
                    };
                    priceFeeds = [];
                    if (reserveData.priceFeedID) {
                        priceFeeds.push(reserveData.priceFeedID);
                    }
                    return [4 /*yield*/, nxSendTransaction(Ixs, data, priceFeeds)];
                case 3:
                    signature = _b.sent();
                    return [2 /*return*/, signature];
            }
        });
    });
}
function getBorrowRemindingAccountList(nxlend_acc_data, reserve_str) {
    var reserve_list = JSON.parse(JSON.stringify(appStore.reserve.state.reserveList));
    var nxlendAccData = nxlend_acc_data;
    var mybalance = nxlendAccData.userMarketInfo.userReserves;
    var activeBalanceList = [];
    var newBorrow = true;
    mybalance.forEach(function (e) {
        if (e.active) {
            if (e.reservePk.toBase58() === reserve_str)
                newBorrow = false;
        }
    });
    var newBorrowAdded = false;
    mybalance.forEach(function (e) {
        if (e.active) {
            activeBalanceList.push(e.reservePk);
        }
        else {
            if (!newBorrowAdded && newBorrow) {
                activeBalanceList.push(new web3.PublicKey(reserve_str));
                newBorrowAdded = true;
            }
        }
    });
    var arr = [];
    var priceFeeds = [];
    activeBalanceList.forEach(function (r) {
        reserve_list.forEach(function (e) {
            if (e.reserve_key === r.toBase58()) {
                var reserve = new web3.PublicKey(e.reserve_key);
                arr.push({
                    isSigner: false,
                    isWritable: true,
                    pubkey: reserve
                });
                var pythOracleReserve = new web3.PublicKey(e.pythOracle);
                arr.push({
                    isSigner: false,
                    isWritable: true,
                    pubkey: pythOracleReserve
                });
                var priceFeed = e.priceFeedID;
                if (priceFeed) {
                    priceFeeds.push(priceFeed);
                }
            }
        });
    });
    return { borrowRemindingAccountList: arr, priceFeeds: priceFeeds };
}
export function lendingAccountBorrow(amount, nxlend_acc, reserve_str, reserve_mint_str, decimals, reserveData) {
    return __awaiter(this, void 0, void 0, function () {
        var reserve, reserve_mint, _a, liquidity_vault, liquidity_vault_bump, reserve_l_v_g, _b, liquidity_vault_authority, liquidity_vault_authority_bump, reserve_l_v_a_g, publicKey, wallet, program, user_token_data, nxlend_acc_pbk, amountInt, borrow_amount, _c, borrowRemindingAccountList, priceFeeds, ix, Ixs, data, signature;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    reserve = new web3.PublicKey(reserve_str);
                    reserve_mint = new web3.PublicKey(reserve_mint_str);
                    _a = web3.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_seed")), reserve.toBuffer()], programLendingId), liquidity_vault = _a[0], liquidity_vault_bump = _a[1];
                    reserve_l_v_g = liquidity_vault;
                    _b = web3.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_auth_seed")), reserve.toBuffer()], programLendingId), liquidity_vault_authority = _b[0], liquidity_vault_authority_bump = _b[1];
                    reserve_l_v_a_g = liquidity_vault_authority;
                    publicKey = useWallet().publicKey;
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    return [4 /*yield*/, makeTokenAccountIx(connection, publicKey.value, reserve_mint, publicKey.value, true)];
                case 1:
                    user_token_data = _d.sent();
                    nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key);
                    amountInt = decimalToInteger(amount, decimals);
                    borrow_amount = new anchor.BN(amountInt);
                    _c = getBorrowRemindingAccountList(nxlend_acc.data, reserve_str), borrowRemindingAccountList = _c.borrowRemindingAccountList, priceFeeds = _c.priceFeeds;
                    return [4 /*yield*/, program.methods.borrowFromReserve(borrow_amount).accounts({
                            nxlendMarket: marketAcc,
                            nxlendAccount: nxlend_acc_pbk,
                            signer: publicKey.value,
                            reserve: reserve,
                            destinationTokenAccount: user_token_data.AssociatedTokenAddress,
                            tokenAccountAuthority: reserve_l_v_a_g,
                            reserveTokenAccount: reserve_l_v_g,
                            tokenProgram: token.TOKEN_PROGRAM_ID
                        })
                            .remainingAccounts(borrowRemindingAccountList)
                            .signers([]).instruction()];
                case 2:
                    ix = _d.sent();
                    Ixs = reserve_mint_str === NATIVE_MINT.toBase58() ? wrapInstructionForWSol(publicKey.value, ix, 0) : [ix];
                    Ixs = [user_token_data.ix].concat(Ixs);
                    data = {
                        type: 'Borrowing',
                        text: "Borrowing ".concat(amount, " ").concat(reserveData.token_name, " from the market"),
                        typed: 'Borrowing',
                        textd: "Borrowing ".concat(amount, " ").concat(reserveData.token_name, " from the market"),
                    };
                    return [4 /*yield*/, nxSendTransaction(Ixs, data, priceFeeds)];
                case 3:
                    signature = _d.sent();
                    return [2 /*return*/, signature];
            }
        });
    });
}
export function lendingAccountRepay(amount, nxlend_acc, reserve_str, reserve_mint_str, decimals, all, reserveData) {
    return __awaiter(this, void 0, void 0, function () {
        var reserve, reserve_mint, _a, liquidity_vault, liquidity_vault_bump, reserve_l_v_g, _b, liquidity_vault_authority, liquidity_vault_authority_bump, reserve_l_v_a_g, publicKey, wallet, program, user_token_data, nxlend_acc_pbk, amountInt, repay_amount, ix, Ixs, data, priceFeeds, signature;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    reserve = new web3.PublicKey(reserve_str);
                    reserve_mint = new web3.PublicKey(reserve_mint_str);
                    _a = web3.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_seed")), reserve.toBuffer()], programLendingId), liquidity_vault = _a[0], liquidity_vault_bump = _a[1];
                    reserve_l_v_g = liquidity_vault;
                    _b = web3.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_auth_seed")), reserve.toBuffer()], programLendingId), liquidity_vault_authority = _b[0], liquidity_vault_authority_bump = _b[1];
                    reserve_l_v_a_g = liquidity_vault_authority;
                    publicKey = useWallet().publicKey;
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    return [4 /*yield*/, makeTokenAccountIx(connection, publicKey.value, reserve_mint, publicKey.value, true)];
                case 1:
                    user_token_data = _c.sent();
                    nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key);
                    amountInt = decimalToInteger(amount, decimals);
                    repay_amount = new anchor.BN(amountInt);
                    return [4 /*yield*/, program.methods.repayToReserve(repay_amount, all
                        //repay_amount,false
                        ).accounts({
                            nxlendMarket: marketAcc,
                            nxlendAccount: nxlend_acc_pbk,
                            signer: publicKey.value,
                            reserve: reserve,
                            signerTokenAccount: user_token_data.AssociatedTokenAddress,
                            reserveTokenAccount: reserve_l_v_g,
                            tokenProgram: token.TOKEN_PROGRAM_ID
                        })
                            .signers([])
                            .instruction()];
                case 2:
                    ix = _c.sent();
                    Ixs = reserve_mint_str === NATIVE_MINT.toBase58() ? wrapInstructionForWSol(publicKey.value, ix, amountInt) : [ix];
                    data = {
                        type: 'Repaying',
                        text: "Repaying ".concat(amount, " ").concat(reserveData.token_name, " into the market"),
                        typed: 'Repaying',
                        textd: "Repaying ".concat(amount, " ").concat(reserveData.token_name, " into the market"),
                    };
                    priceFeeds = [];
                    if (reserveData.priceFeedID) {
                        priceFeeds.push(reserveData.priceFeedID);
                    }
                    return [4 /*yield*/, nxSendTransaction(Ixs, data, priceFeeds)];
                case 3:
                    signature = _c.sent();
                    return [2 /*return*/, signature];
            }
        });
    });
}
function i80f48FromNumber(x) {
    var MULTIPLIER_NUMBER = Math.pow(2, 48);
    var int_part = Math.trunc(x);
    var v = new anchor.BN(int_part).iushln(48);
    v.iadd(new anchor.BN((x - int_part) * MULTIPLIER_NUMBER));
    return v;
}
export function setRepayLTV(nxlend_acc, amount) {
    return __awaiter(this, void 0, void 0, function () {
        var publicKey, nxlend_acc_pbk, wallet, program, numberAmount, i80f48Amount, ix, Ixs, data, signature;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    publicKey = useWallet().publicKey;
                    nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key);
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    numberAmount = Number(amount);
                    i80f48Amount = i80f48FromNumber(numberAmount);
                    return [4 /*yield*/, program.methods.setAccountRepayLtv(
                        //i80f48Amount
                        { value: i80f48Amount }).accounts({
                            nxlendMarket: marketAcc,
                            nxlendAccount: nxlend_acc_pbk,
                            owner: publicKey.value
                        })
                            .signers([])
                            .instruction()];
                case 1:
                    ix = _a.sent();
                    Ixs = [ix];
                    data = {
                        type: 'Set Repay LTV',
                        text: "Set Repay LTV at ".concat(amount * 100, " %"),
                        typed: 'Set Repay LTV',
                        textd: "Set Repay LTV at ".concat(amount * 100, " %"),
                    };
                    return [4 /*yield*/, nxSendTransaction(Ixs, data)];
                case 2:
                    signature = _a.sent();
                    return [2 /*return*/, signature];
            }
        });
    });
}
function getWithdrawRemindingAccountList(nxlend_acc_data, reserve_str, all) {
    var reserve_list = JSON.parse(JSON.stringify(appStore.reserve.state.reserveList));
    var nxlendAccData = nxlend_acc_data;
    var mybalance = nxlendAccData.userMarketInfo.userReserves;
    var activeBalanceList = [];
    mybalance.forEach(function (e) {
        if (e.active) {
            activeBalanceList.push(e);
        }
    });
    var arr = [];
    var priceFeeds = [];
    activeBalanceList.forEach(function (r) {
        if (r.reservePk.toBase58() !== reserve_str || !all) {
            reserve_list.forEach(function (e) {
                if (e.reserve_key === r.reservePk.toBase58()) {
                    var reserve = new web3.PublicKey(e.reserve_key);
                    arr.push({
                        isSigner: false,
                        isWritable: true,
                        pubkey: reserve
                    });
                    var pythOracleReserve = new web3.PublicKey(e.pythOracle);
                    arr.push({
                        isSigner: false,
                        isWritable: true,
                        pubkey: pythOracleReserve
                    });
                    var priceFeed = e.priceFeedID;
                    if (priceFeed) {
                        priceFeeds.push(priceFeed);
                    }
                }
            });
        }
    });
    return { withdrawRemindingAccountList: arr, priceFeeds: priceFeeds };
}
export function lendingAccountWithdraw(amount, nxlend_acc, reserve_str, reserve_mint_str, all, decimals, reserveData) {
    return __awaiter(this, void 0, void 0, function () {
        var reserve, reserve_mint, _a, liquidity_vault, liquidity_vault_bump, reserve_l_v_g, _b, liquidity_vault_authority, liquidity_vault_authority_bump, reserve_l_v_a_g, publicKey, wallet, program, user_token_data, nxlend_acc_pbk, amountInt, withdraw_amount, _c, withdrawRemindingAccountList, priceFeeds, ix, Ixs, data, signature;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    reserve = new web3.PublicKey(reserve_str);
                    reserve_mint = new web3.PublicKey(reserve_mint_str);
                    _a = web3.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_seed")), reserve.toBuffer()], programLendingId), liquidity_vault = _a[0], liquidity_vault_bump = _a[1];
                    reserve_l_v_g = liquidity_vault;
                    _b = web3.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_auth_seed")), reserve.toBuffer()], programLendingId), liquidity_vault_authority = _b[0], liquidity_vault_authority_bump = _b[1];
                    reserve_l_v_a_g = liquidity_vault_authority;
                    publicKey = useWallet().publicKey;
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    return [4 /*yield*/, makeTokenAccountIx(connection, publicKey.value, reserve_mint, publicKey.value, true)];
                case 1:
                    user_token_data = _d.sent();
                    nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key);
                    amountInt = decimalToInteger(amount, decimals);
                    withdraw_amount = new anchor.BN(amountInt);
                    return [4 /*yield*/, getWithdrawRemindingAccountList(nxlend_acc.data, reserve_str, all)
                        //const remindingAccountList = await getWithdrawRemindingAccountList(nxlend_acc.data,reserve_str,false)
                    ];
                case 2:
                    _c = _d.sent(), withdrawRemindingAccountList = _c.withdrawRemindingAccountList, priceFeeds = _c.priceFeeds;
                    //const remindingAccountList = await getWithdrawRemindingAccountList(nxlend_acc.data,reserve_str,false)
                    console.log('withdraw remindingAccountList', withdrawRemindingAccountList);
                    return [4 /*yield*/, program.methods.withdrawFromReserve(withdraw_amount, all
                        //withdraw_amount, false
                        ).accounts({
                            nxlendMarket: marketAcc,
                            nxlendAccount: nxlend_acc_pbk,
                            signer: publicKey.value,
                            reserve: reserve,
                            destinationTokenAccount: user_token_data.AssociatedTokenAddress,
                            tokenAccountAuthority: reserve_l_v_a_g,
                            reserveTokenAccount: reserve_l_v_g,
                            tokenProgram: token.TOKEN_PROGRAM_ID
                        })
                            .remainingAccounts(withdrawRemindingAccountList)
                            .signers([])
                            .instruction()];
                case 3:
                    ix = _d.sent();
                    Ixs = reserve_mint_str === NATIVE_MINT.toBase58() ? wrapInstructionForWSol(publicKey.value, ix, 0) : [ix];
                    Ixs = [user_token_data.ix].concat(Ixs);
                    data = {
                        type: 'Withdrawing',
                        text: "Withdrawing ".concat(amount, " ").concat(reserveData.token_name, " from the market"),
                        typed: 'Withdrawing',
                        textd: "Withdrawing ".concat(amount, " ").concat(reserveData.token_name, " from the market"),
                    };
                    return [4 /*yield*/, nxSendTransaction(Ixs, data, priceFeeds)];
                case 4:
                    signature = _d.sent();
                    return [2 /*return*/, signature];
            }
        });
    });
}
export function getBalance(token_account) {
    return __awaiter(this, void 0, void 0, function () {
        var tokenAccountBalance2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('token_account', token_account);
                    return [4 /*yield*/, connection.getTokenAccountBalance(token_account)];
                case 1:
                    tokenAccountBalance2 = _a.sent();
                    return [2 /*return*/, tokenAccountBalance2.value.uiAmount];
            }
        });
    });
}
function makeTokenAccountIx(connection, payer, mint, owner, allowOwnerOffCurve, needNew) {
    if (needNew === void 0) { needNew = true; }
    return __awaiter(this, void 0, void 0, function () {
        var AssociatedTokenAddress, ix, tokenAccount, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, token.getAssociatedTokenAddress(mint, owner)];
                case 1:
                    AssociatedTokenAddress = _a.sent();
                    ix = token.createAssociatedTokenAccountIdempotentInstruction(payer, // payer
                    AssociatedTokenAddress, // ata
                    owner, // owner
                    mint // mint
                    );
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, token.getAccount(connection, AssociatedTokenAddress)];
                case 3:
                    tokenAccount = _a.sent();
                    return [2 /*return*/, {
                            AssociatedTokenAddress: AssociatedTokenAddress,
                            ix: ix,
                            isExit: true
                        }];
                case 4:
                    error_7 = _a.sent();
                    return [2 /*return*/, {
                            AssociatedTokenAddress: AssociatedTokenAddress,
                            ix: ix,
                            isExit: false
                        }];
                case 5: return [2 /*return*/];
            }
        });
    });
}
function createTokenAccount(connection, payer, mint, owner, allowOwnerOffCurve, needNew) {
    if (needNew === void 0) { needNew = true; }
    return __awaiter(this, void 0, void 0, function () {
        var AssociatedTokenAddress, tokenAccount, error_8, transaction, sendTransaction_1, transactionSignature;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, token.getAssociatedTokenAddress(mint, owner, allowOwnerOffCurve)];
                case 1:
                    AssociatedTokenAddress = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 7]);
                    return [4 /*yield*/, token.getAccount(connection, AssociatedTokenAddress)];
                case 3:
                    tokenAccount = _a.sent();
                    return [2 /*return*/, tokenAccount];
                case 4:
                    error_8 = _a.sent();
                    console.log(error_8);
                    if (!needNew)
                        return [2 /*return*/, null];
                    transaction = new web3.Transaction();
                    sendTransaction_1 = useWallet().sendTransaction;
                    transaction.add(token.createAssociatedTokenAccountInstruction(payer, // payer
                    AssociatedTokenAddress, // ata
                    owner, // owner
                    mint // mint
                    ));
                    return [4 /*yield*/, sendTransaction_1(transaction, connection)];
                case 5:
                    transactionSignature = _a.sent();
                    return [4 /*yield*/, createTokenAccount(connection, payer, mint, owner, allowOwnerOffCurve)];
                case 6: return [2 /*return*/, _a.sent()];
                case 7: return [2 /*return*/];
            }
        });
    });
}
function getTotalAssetQuantity(totalAssetShares, assetShareValue) {
    return totalAssetShares.times(assetShareValue);
}
function getTotalLiabilityQuantity(totalLiabilityShares, liabilityShareValue) {
    return totalLiabilityShares.times(liabilityShareValue);
}
function getAssetQuantity(assetShares, assetShareValue) {
    return assetShares.times(assetShareValue);
}
function getLiabilityQuantity(liabilityShares, liabilityShareValue) {
    return liabilityShares.times(liabilityShareValue);
}
function getAssetShares(assetQuantity, assetShareValue) {
    return assetQuantity.times(assetShareValue);
}
function getLiabilityShares(liabilityQuantity, liabilityShareValue) {
    return liabilityQuantity.times(liabilityShareValue);
}
function makeWrapSolIxs(walletAddress, amount) {
    var address = getAssociatedTokenAddressSync(NATIVE_MINT, walletAddress, true);
    var ixs = [createAssociatedTokenAccountIdempotentInstruction(walletAddress, address, walletAddress, NATIVE_MINT)];
    if (amount.gt(0)) {
        // const nativeAmount = uiToNative(amount, 9).toNumber() + 10000;
        // const nativeAmount = amount.plus(10000).toNumber();
        var nativeAmount = Number(amount.times(new BigNumber(1).plus(SOL_WRAP_FACTOR)).toNumber().toFixed(0));
        console.log(amount);
        console.log(nativeAmount);
        ixs.push(web3.SystemProgram.transfer({ fromPubkey: walletAddress, toPubkey: address, lamports: nativeAmount }), createSyncNativeInstruction(address));
    }
    return ixs;
}
function makeUnwrapSolIx(walletAddress) {
    var address = getAssociatedTokenAddressSync(NATIVE_MINT, walletAddress, true);
    return createCloseAccountInstruction(address, walletAddress, walletAddress);
}
function wrapInstructionForWSol(walletAddress, ix, amount) {
    return __spreadArray(__spreadArray([], makeWrapSolIxs(walletAddress, new BigNumber(amount)), true), [ix, makeUnwrapSolIx(walletAddress)], false);
}
export function confirmTransaction(tx) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function () {
        var i, flag, result;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    i = 0;
                    flag = false;
                    _d.label = 1;
                case 1:
                    if (!(i < 100)) return [3 /*break*/, 10];
                    console.log('check times', i);
                    return [4 /*yield*/, connection.getSignatureStatus(tx, {
                            searchTransactionHistory: true,
                        })];
                case 2:
                    result = _d.sent();
                    if (!!result) return [3 /*break*/, 4];
                    return [4 /*yield*/, delay(500)];
                case 3:
                    _d.sent();
                    i = i + 1;
                    return [3 /*break*/, 9];
                case 4:
                    console.log('check res', (_a = result.value) === null || _a === void 0 ? void 0 : _a.confirmationStatus);
                    console.log('check res', result);
                    if (!!result.value) return [3 /*break*/, 6];
                    return [4 /*yield*/, delay(500)];
                case 5:
                    _d.sent();
                    i = i + 1;
                    return [3 /*break*/, 9];
                case 6:
                    i = i + 1;
                    if (!(((_b = result.value) === null || _b === void 0 ? void 0 : _b.confirmationStatus) === 'processed')) return [3 /*break*/, 8];
                    return [4 /*yield*/, delay(500)];
                case 7:
                    _d.sent();
                    _d.label = 8;
                case 8:
                    if (((_c = result.value) === null || _c === void 0 ? void 0 : _c.confirmationStatus) === 'confirmed') {
                        flag = true;
                        i = 100;
                    }
                    _d.label = 9;
                case 9: return [3 /*break*/, 1];
                case 10: return [2 /*return*/, flag];
            }
        });
    });
}
function getPriceUpdateData($priceFeeds) {
    return __awaiter(this, void 0, void 0, function () {
        var priceServiceConnection, priceFeeds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    priceServiceConnection = new PriceServiceConnection("https://hermes.pyth.network/", { priceFeedRequestConfig: { binary: true } });
                    priceFeeds = [];
                    if ($priceFeeds.length < 1) {
                        priceFeeds.push('0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a');
                    }
                    else {
                        priceFeeds = $priceFeeds;
                    }
                    return [4 /*yield*/, priceServiceConnection.getLatestVaas(priceFeeds)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function nxSendTransaction($ixs, $data, $priceFeeds) {
    return __awaiter(this, void 0, void 0, function () {
        var Ixs, _a, publicKey, signTransaction, feeSetJson, feeSet, feeAmount, feeInt, computePriceIx, setComputeUnitLimitInstruction, slot, block, getLatestBlockhashAndContext, blockhash, lookupTableAccount, messageV0, transactionV0, tx, queryString, urlParams, paramValue, updateSelf, finalTransactions, wallet, pythSolanaReceiver, priceUpdateData, _b, postInstructions, closeInstructions, priceFeedIdToPriceUpdateAccount, ConsumerInstructions, transactions, data, error_9, data, error_10;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    Ixs = $ixs;
                    console.log('$ixs', $ixs);
                    _a = useWallet(), publicKey = _a.publicKey, signTransaction = _a.signTransaction;
                    feeSetJson = window.localStorage.getItem('feeSet');
                    feeSet = JSON.parse(feeSetJson);
                    feeAmount = feeSet ? feeSet.feeAmount : 0;
                    feeInt = decimalToInteger(feeAmount, 9);
                    if (feeAmount > 0) {
                        computePriceIx = web3.ComputeBudgetProgram.setComputeUnitPrice({
                            microLamports: Number(feeInt),
                        });
                        Ixs = [computePriceIx].concat(Ixs);
                    }
                    setComputeUnitLimitInstruction = web3.ComputeBudgetProgram.setComputeUnitLimit({ units: 400000 });
                    Ixs = [setComputeUnitLimitInstruction].concat(Ixs);
                    console.log('$ixs', $ixs);
                    return [4 /*yield*/, connection.getSlot()];
                case 1:
                    slot = _c.sent();
                    return [4 /*yield*/, connection.getBlock(slot, {
                            maxSupportedTransactionVersion: 0,
                        })];
                case 2:
                    block = _c.sent();
                    return [4 /*yield*/, connection.getLatestBlockhashAndContext()];
                case 3:
                    getLatestBlockhashAndContext = _c.sent();
                    blockhash = getLatestBlockhashAndContext.value.blockhash;
                    return [4 /*yield*/, connection.getAddressLookupTable(lookUpTable)];
                case 4:
                    lookupTableAccount = (_c.sent()).value;
                    messageV0 = new web3.TransactionMessage({
                        payerKey: publicKey.value,
                        recentBlockhash: blockhash,
                        instructions: Ixs, // Note: this is an array of instructions
                    }).compileToV0Message([lookupTableAccount]);
                    transactionV0 = new web3.VersionedTransaction(messageV0);
                    queryString = window.location.search;
                    urlParams = new URLSearchParams(queryString);
                    paramValue = urlParams.get('fee');
                    console.log(paramValue);
                    updateSelf = paramValue === 'true' ? true : false;
                    if (!updateSelf) return [3 /*break*/, 13];
                    wallet = useAnchorWallet();
                    pythSolanaReceiver = new PythSolanaReceiver({
                        connection: connection,
                        wallet: wallet.value,
                    });
                    return [4 /*yield*/, getPriceUpdateData($priceFeeds)];
                case 5:
                    priceUpdateData = _c.sent();
                    console.log('priceUpdateData', priceUpdateData);
                    return [4 /*yield*/, pythSolanaReceiver.buildUpdatePriceFeedInstructions(priceUpdateData, 0)];
                case 6:
                    _b = _c.sent(), postInstructions = _b.postInstructions, closeInstructions = _b.closeInstructions, priceFeedIdToPriceUpdateAccount = _b.priceFeedIdToPriceUpdateAccount;
                    console.log('postInstructions', postInstructions);
                    console.log('Ixs', Ixs);
                    ConsumerInstructions = $ixs.map(function (ix) {
                        var priceix = {
                            instruction: ix,
                            signers: [],
                        };
                        return priceix;
                    });
                    return [4 /*yield*/, pythSolanaReceiver.batchIntoVersionedTransactions(__spreadArray(__spreadArray(__spreadArray([], postInstructions, true), ConsumerInstructions, true), closeInstructions, true), { computeUnitPriceMicroLamports: 50000 })];
                case 7:
                    transactions = _c.sent();
                    _c.label = 8;
                case 8:
                    _c.trys.push([8, 11, , 12]);
                    return [4 /*yield*/, pythSolanaReceiver.provider.sendAll(__spreadArray([], transactions, true), {
                            skipPreflight: false,
                            preflightCommitment: "processed",
                        })];
                case 9:
                    tx = _c.sent();
                    console.log('tx', tx);
                    data = {
                        type: $data.type,
                        text: $data.text,
                        typed: $data.typed,
                        textd: $data.textd,
                        tx: updateSelf ? tx[tx.length - 1] : tx
                    };
                    appStore.pendingtx.state.txList.push(data);
                    return [4 /*yield*/, connection.confirmTransaction(updateSelf ? tx[tx.length - 1] : tx, "confirmed")];
                case 10:
                    _c.sent();
                    //await connection.confirmTransaction(tx, "confirmed");
                    //await confirmTransaction(tx);
                    firework1();
                    return [2 /*return*/, tx];
                case 11:
                    error_9 = _c.sent();
                    console.log('error', error_9);
                    if (error_9.toString().indexOf('User rejected') > -1) {
                        ElMessage({
                            message: 'User rejected',
                            type: 'warning',
                        });
                    }
                    return [2 /*return*/, false];
                case 12: return [3 /*break*/, 17];
                case 13:
                    _c.trys.push([13, 16, , 17]);
                    return [4 /*yield*/, sendTransaction(transactionV0, connection)];
                case 14:
                    tx = _c.sent();
                    console.log('tx', tx);
                    data = {
                        type: $data.type,
                        text: $data.text,
                        typed: $data.typed,
                        textd: $data.textd,
                        tx: updateSelf ? tx[tx.length - 1] : tx
                    };
                    appStore.pendingtx.state.txList.push(data);
                    return [4 /*yield*/, connection.confirmTransaction(updateSelf ? tx[tx.length - 1] : tx, "confirmed")];
                case 15:
                    _c.sent();
                    //await connection.confirmTransaction(tx, "confirmed");
                    //await confirmTransaction(tx);
                    firework1();
                    return [2 /*return*/, tx];
                case 16:
                    error_10 = _c.sent();
                    console.log('error', error_10);
                    if (error_10.toString().indexOf('User rejected') > -1) {
                        ElMessage({
                            message: 'User rejected',
                            type: 'warning',
                        });
                    }
                    return [2 /*return*/, false];
                case 17: return [2 /*return*/];
            }
        });
    });
}
export function updateReserve(reserveKey, setFlag) {
    return __awaiter(this, void 0, Promise, function () {
        var publicKey, wallet, program, assetValueRatio, assetValueLiqRatio, debtValueRatioHighRisk, debtValueRatioMidRisk, debtValueRatioLowRisk, debtValueLiqRatio, tx;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    publicKey = useWallet().publicKey;
                    wallet = useAnchorWallet();
                    anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value, {}));
                    program = new anchor.Program(idl, programLendingId);
                    if (setFlag) {
                        assetValueRatio = 0.5;
                        assetValueLiqRatio = 0.65;
                        // assetValueRatio = 0
                        // assetValueLiqRatio = 0
                        debtValueRatioHighRisk = 15.1;
                        debtValueRatioMidRisk = 15.5;
                        debtValueRatioLowRisk = 16;
                        debtValueLiqRatio = 15;
                    }
                    else {
                        assetValueRatio = 0.5;
                        assetValueLiqRatio = 0.65;
                        // assetValueRatio = 0
                        // assetValueLiqRatio = 0
                        debtValueRatioHighRisk = 2;
                        debtValueRatioMidRisk = 2.5;
                        debtValueRatioLowRisk = 3;
                        debtValueLiqRatio = 1.5;
                    }
                    console.log('start change reserve');
                    return [4 /*yield*/, program.methods.updateReserve({
                            assetValueRatio: {
                                value: i80f48FromNumber(assetValueRatio)
                            },
                            assetValueLiqRatio: {
                                value: i80f48FromNumber(assetValueLiqRatio)
                            },
                            debtValueRatioHighRisk: {
                                value: i80f48FromNumber(debtValueRatioHighRisk)
                            },
                            debtValueRatioMidRisk: {
                                value: i80f48FromNumber(debtValueRatioMidRisk)
                            },
                            debtValueRatioLowRisk: {
                                value: i80f48FromNumber(debtValueRatioLowRisk)
                            },
                            debtValueLiqRatio: {
                                value: i80f48FromNumber(debtValueLiqRatio)
                            },
                            capacity: null,
                            maxBorrowable: null,
                            operationalState: null,
                            oracle: null,
                            interestSetting: null,
                            reserveType: null,
                            maxExposure: null,
                            maxPriceAge: null
                        }).accounts({
                            nxlendMarket: marketAcc,
                            manager: publicKey.value,
                            reserve: reserveKey
                        })
                            .signers([])
                            .rpc()];
                case 1:
                    tx = _a.sent();
                    return [2 /*return*/, reserveKey];
            }
        });
    });
}
var aprToApy = function (apr, compoundingFrequency) {
    if (compoundingFrequency === void 0) { compoundingFrequency = HOURS_PER_YEAR; }
    return Math.pow((1 + apr / compoundingFrequency), compoundingFrequency) - 1;
};
export function getFee() {
    return __awaiter(this, void 0, void 0, function () {
        var res, fee_1, feeNumber, error_11, feeNumber;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, connection.getRecentPrioritizationFees()];
                case 1:
                    res = _a.sent();
                    fee_1 = 0;
                    res.forEach(function (e) {
                        if (e.prioritizationFee > fee_1) {
                            fee_1 = e.prioritizationFee;
                        }
                    });
                    feeNumber = integerToDecimal(fee_1, 9);
                    return [2 /*return*/, feeNumber];
                case 2:
                    error_11 = _a.sent();
                    feeNumber = integerToDecimal(5000, 9);
                    return [2 /*return*/, feeNumber];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function firework1() {
    var end = Date.now() + 2 * 1000;
    // go Buckeyes!
    var colors = ["#7340c9", "#ffffff"];
    (function frame() {
        confetti({
            shapes: ["star"],
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors,
        });
        confetti({
            shapes: ["star"],
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors,
        });
        if (Date.now() < end) {
            requestAnimationFrame(frame);
        }
    })();
}
export function swap(swapModeExactOut) {
    var _a;
    if (swapModeExactOut === void 0) { swapModeExactOut = false; }
    return __awaiter(this, void 0, void 0, function () {
        var publicKey, SLIPPAGE_BPS, TX_FEE, mintOut, mintIn, amount, swapMode, swapUrl, quoteApiResponse, data, transactionResponse, swapTransaction, swapTransactionBuf, transaction, txid;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    publicKey = useWallet().publicKey;
                    SLIPPAGE_BPS = 2500;
                    TX_FEE = 200000;
                    mintOut = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v');
                    mintIn = new PublicKey('Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB');
                    amount = new anchor.BN(200000);
                    swapMode = swapModeExactOut ? "ExactOut" : "ExactIn";
                    swapUrl = "https://quote-api.jup.ag/v6/quote?inputMint=".concat(mintIn.toBase58(), "&outputMint=").concat(mintOut.toBase58(), "&amount=").concat(amount.toString(), "&slippageBps=").concat(SLIPPAGE_BPS, "&swapMode=").concat(swapMode);
                    console.log('swapUrl', swapUrl);
                    return [4 /*yield*/, fetch(swapUrl)];
                case 1:
                    quoteApiResponse = _b.sent();
                    return [4 /*yield*/, quoteApiResponse.json()];
                case 2:
                    data = _b.sent();
                    return [4 /*yield*/, fetch("https://quote-api.jup.ag/v6/swap", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                // quoteResponse from /quote api
                                quoteResponse: data,
                                // user public key to be used for the swap
                                userPublicKey: (_a = publicKey.value) === null || _a === void 0 ? void 0 : _a.toBase58(),
                                // auto wrap and unwrap SOL. default is true
                                wrapUnwrapSOL: true,
                                // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
                                //feeAccount: wallet.publicKey.toString(),
                                computeUnitPriceMicroLamports: TX_FEE,
                            }),
                        })];
                case 3: return [4 /*yield*/, (_b.sent()).json()];
                case 4:
                    transactionResponse = _b.sent();
                    console.log('transactionResponse', transactionResponse);
                    swapTransaction = transactionResponse.swapTransaction;
                    swapTransactionBuf = Buffer.from(swapTransaction, "base64");
                    transaction = web3.VersionedTransaction.deserialize(swapTransactionBuf);
                    txid = sendTransaction(transaction, mainConnection);
                    // transaction.sign([wallet.payer]);
                    // const rawTransaction = transaction.serialize();
                    // const txid = await connection.sendRawTransaction(rawTransaction, {
                    //   maxRetries: 2,
                    // });
                    //await connection.confirmTransaction(txid, "confirmed");
                    console.log('txid', txid);
                    return [2 /*return*/];
            }
        });
    });
}
