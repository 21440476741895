import { pendingtx } from './pendingtx'
import { reserve } from './reserve'
import { profit } from './profit'
import { account } from './account'

export interface IAppStore {
  profit: ReturnType<typeof profit>
  reserve: ReturnType<typeof reserve>
  account: ReturnType<typeof account>
  pendingtx: ReturnType<typeof pendingtx>
}
const appStore: IAppStore = {} as IAppStore

/**
 * 注册app状态库
 */
export const registerStore = () => {
  appStore.profit = profit()
  appStore.reserve = reserve()
  appStore.account = account()
  appStore.pendingtx = pendingtx()
}

export default appStore
