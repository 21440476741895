/* eslint-disable */
export const BORROW_MAX_DISCOUNT = 0.997
export const programLendingIdStr = '7YYkqwXp812NMe6nWny2JAGsm6b3CVvbQKiMo8SuaPMg'
export const marketAccKey = 'Dd6n8S8E4SEfgtBm6QQ3z22zT3fSzTwJPvdjuGSshSVW'
export const usd_oracle_key = '5SSkXsEKQepHHAewytPVwdej4epN1nxgLVM84L4KXgy7'
export const usdc_mint_key = '6QGCu1RaFWWP4JuM7CyM4ypzxXPdkW8515xph6HaoQTp'
export const look_up_address = '5pEaz66NQra8hrjXSBnW76sBj8xS9rudWZP8RR8rCjqT'
export const node_rpc = 'https://solana-devnet.g.alchemy.com/v2/pjreqKafE1VzG7mJQCPopTljP98obyzG'
export const HOURS_PER_YEAR = 365.25 * 24
export const INTERSEST_MIN_DISCOUNT = 0.003
export const MAX_BALANCES_AMOUNT = 15
export const SOL_WRAP_FACTOR = 0.0005
export const RESERVE_ONLINE_DATA = 'https://nxfiio.s3.ap-east-1.amazonaws.com/reserve.json'
export const needUpdate = false
export const reserve_list = [
    {
        token_name: 'SOL',
        token_img: require('@/assets/token/sol.webp'),
        reserve_mint_key: 'So11111111111111111111111111111111111111112',
        reserve_key: 'D9ZVWiNhv7QVxJfVSb2F3A27ZeQ9mQZckBHjdqqV8iRp',
        pythOracle: 'J83w4HKfqxwcq3BEMMkPFSppX3gqekLyLJBexebFVkix',
        reserveAta: '',
        total_supply: 0,
        total_borrow: 0,
        supply_apr: 0,
        borrow_apr: 0,
        supply_apy: 0,
        borrow_apy: 0,
        ltv: 0,
        asset_ntoken_ratio: 0,
        debit_ntoken_ratio: 0,
        my_supply: 0,
        my_borrow: 0,
        amount: 0,
        usd_price: 0,
        usd_price_low: 0,
        usd_price_high: 0,
        usd_price_weighted: 0,
        usd_price_weighted_low: 0,
        usd_price_weighted_high: 0,
        decimals:0,
        asset_ratio: 0,
        asset_liq_ratio: 0,
        debit_set_ratio: 1.5,
        debit_high_ratio: 0,
        debit_mid_ratio: 0,
        debit_low_ratio: 0,
        debit_liq_ratio: 0,
        type:1,
        interestSetting:{}
    },
    {
        token_name: 'nSOL',
        token_img: require('@/assets/token/sol.webp'),
        reserve_mint_key: 'AmHbtnQxTTGSWq5UTShUuxhpHegnAAWACYuZ7Q6DDNaB',
        reserve_key: 'Er9x4qcQYwZMMnLYF4X2tL4wwBcWEiWimX8d7B5DpoQK',
        pythOracle: 'J83w4HKfqxwcq3BEMMkPFSppX3gqekLyLJBexebFVkix',
        reserveAta: '',
        total_supply: 0,
        total_borrow: 0,
        supply_apr: 0,
        borrow_apr: 0,
        supply_apy: 0,
        borrow_apy: 0,
        ltv: 0,
        asset_ntoken_ratio: 0,
        debit_ntoken_ratio: 0,
        my_supply: 0,
        my_borrow: 0,
        amount: 0,
        usd_price: 0,
        usd_price_low: 0,
        usd_price_high: 0,
        usd_price_weighted: 0,
        usd_price_weighted_low: 0,
        usd_price_weighted_high: 0,
        decimals:0,
        asset_ratio: 0.85,
        asset_liq_ratio: 0.9,
        debit_high_ratio: 1.1,
        debit_set_ratio: 1.2,
        debit_mid_ratio: 1.2,
        debit_low_ratio: 1.3,
        debit_liq_ratio: 1.05,
        type:1,
        interestSetting:{}
    },
    {
        token_name: 'USDC',
        token_img: require('@/assets/token/usdc.webp'),
        reserve_mint_key: '6QGCu1RaFWWP4JuM7CyM4ypzxXPdkW8515xph6HaoQTp',
        reserve_key: '6Xu8RNQ2JiSxSwUQN5HAJV4gXFtCnaYALM9NhZnDHx5r',
        pythOracle: '5SSkXsEKQepHHAewytPVwdej4epN1nxgLVM84L4KXgy7',
        reserveAta: '',
        total_supply: 0,
        total_borrow: 0,
        supply_apr: 0,
        borrow_apr: 0,
        supply_apy: 0,
        borrow_apy: 0,
        ltv: 0,
        amount: 0,
        asset_ntoken_ratio: 0,
        debit_ntoken_ratio: 0,
        my_supply: 0,
        my_borrow: 0,
        usd_price: 0,
        usd_price_low: 0,
        usd_price_high: 0,
        usd_price_weighted: 0,
        usd_price_weighted_low: 0,
        usd_price_weighted_high: 0,
        decimals:0,
        asset_ratio: 0.85,
        asset_liq_ratio: 0.9,
        debit_high_ratio: 1.1,
        debit_set_ratio: 1.2,
        debit_mid_ratio: 1.2,
        debit_low_ratio: 1.3,
        debit_liq_ratio: 1.05,
        type:1,
        interestSetting:{}
    },
    {
        token_name: 'WBTC',
        token_img: require('@/assets/token/wbtc.webp'),
        reserve_mint_key: '9KQUzF8WNu1JUj63xS8tZL1NB64zdWk1TGA7uUgfBZug',
        reserve_key: 'AKorkcEBB3f3f8rNo6WeptyQXN1XcGnbFAPJdTof1daR',
        pythOracle: 'HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J',
        reserveAta: '',
        total_supply: 0,
        total_borrow: 0,
        supply_apr: 0,
        borrow_apr: 0,
        supply_apy: 0,
        borrow_apy: 0,
        ltv: 0,
        amount: 0,
        asset_ntoken_ratio: 0,
        debit_ntoken_ratio: 0,
        my_supply: 0,
        my_borrow: 0,
        usd_price: 0,
        usd_price_low: 0,
        usd_price_high: 0,
        usd_price_weighted: 0,
        usd_price_weighted_low: 0,
        usd_price_weighted_high: 0,
        decimals:0,
        asset_ratio: 0.85,
        asset_liq_ratio: 0.9,
        debit_high_ratio: 1.1,
        debit_set_ratio: 1.2,
        debit_mid_ratio: 1.2,
        debit_low_ratio: 1.3,
        debit_liq_ratio: 1.05,
        type:1,
        interestSetting:{}
    },
    {
        token_name: 'JUP',
        token_img: require('@/assets/token/jup.webp'),
        reserve_mint_key: 'A9skDeJ6WWpLpMws6MNVyRHPZEG7szWgQH9GXTvHZ6Gw',
        reserve_key: '8NV2nwKWmDF9KD7cRwy2mCSTC6hAPcgVdcW9e1bq2BMz',
        pythOracle: 'Gh9hscza9YaCzr84tNV1NZQfpqoL3csYnWDygDkQmBU2',
        reserveAta: '',
        total_supply: 0,
        total_borrow: 0,
        supply_apr: 0,
        borrow_apr: 0,
        supply_apy: 0,
        borrow_apy: 0,
        ltv: 0,
        amount: 0,
        asset_ntoken_ratio: 0,
        debit_ntoken_ratio: 0,
        my_supply: 0,
        my_borrow: 0,
        usd_price: 0,
        usd_price_low: 0,
        usd_price_high: 0,
        usd_price_weighted: 0,
        usd_price_weighted_low: 0,
        usd_price_weighted_high: 0,
        decimals:0,
        asset_ratio: 0,
        asset_liq_ratio: 0,
        debit_high_ratio: 1.1,
        debit_set_ratio: 1.2,
        debit_mid_ratio: 1.2,
        debit_low_ratio: 1.3,
        debit_liq_ratio: 1.05,
        type:2,
        interestSetting:{}
    },
    {
        token_name: 'JTO',
        token_img: require('@/assets/token/tokenjto.webp'),
        reserve_mint_key: 'rEqAkyqH5nrxsqVAVBCLwRo2jGjV4vnc39tpdxX1XJV',
        reserve_key: 'Au65NWG5oKtsXpxudkKpdjNTyjpKuMo8psE8A2fVrHJP',
        pythOracle: '29xQnTzyyuRtgJ7RtSKEgBWwRzZqtrrKmyQQ5m3x629f',
        reserveAta: '',
        total_supply: 0,
        total_borrow: 0,
        supply_apr: 0,
        borrow_apr: 0,
        supply_apy: 0,
        borrow_apy: 0,
        ltv: 0,
        amount: 0,
        asset_ntoken_ratio: 0,
        debit_ntoken_ratio: 0,
        my_supply: 0,
        my_borrow: 0,
        usd_price: 0,
        usd_price_low: 0,
        usd_price_high: 0,
        usd_price_weighted: 0,
        usd_price_weighted_low: 0,
        usd_price_weighted_high: 0,
        decimals:0,
        asset_ratio: 0,
        asset_liq_ratio: 0,
        debit_high_ratio: 1.1,
        debit_set_ratio: 1.2,
        debit_mid_ratio: 1.2,
        debit_low_ratio: 1.3,
        debit_liq_ratio: 1.05,
        type:1,
        interestSetting:{}
    }
]
export const slider_list = [
    {
        reserve_mint_key: 'So11111111111111111111111111111111111111112',
        reserve_key: 'D9ZVWiNhv7QVxJfVSb2F3A27ZeQ9mQZckBHjdqqV8iRp',
        slider: 50
    },
    {
        reserve_mint_key: 'AmHbtnQxTTGSWq5UTShUuxhpHegnAAWACYuZ7Q6DDNaB',
        reserve_key: 'Er9x4qcQYwZMMnLYF4X2tL4wwBcWEiWimX8d7B5DpoQK',
        slider: 50,
    },
    {
        reserve_mint_key: '6QGCu1RaFWWP4JuM7CyM4ypzxXPdkW8515xph6HaoQTp',
        reserve_key: '6Xu8RNQ2JiSxSwUQN5HAJV4gXFtCnaYALM9NhZnDHx5r',
        slider: 50,
    },
    {
        reserve_mint_key: '9KQUzF8WNu1JUj63xS8tZL1NB64zdWk1TGA7uUgfBZug',
        reserve_key: 'AKorkcEBB3f3f8rNo6WeptyQXN1XcGnbFAPJdTof1daR',
        slider: 50,
    },
    {
        reserve_mint_key: 'A9skDeJ6WWpLpMws6MNVyRHPZEG7szWgQH9GXTvHZ6Gw',
        reserve_key: '8NV2nwKWmDF9KD7cRwy2mCSTC6hAPcgVdcW9e1bq2BMz',
        slider: 50,
    },
    {
        reserve_mint_key: 'rEqAkyqH5nrxsqVAVBCLwRo2jGjV4vnc39tpdxX1XJV',
        reserve_key: 'Au65NWG5oKtsXpxudkKpdjNTyjpKuMo8psE8A2fVrHJP',
        slider: 50,
    }
]