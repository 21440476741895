/* eslint-disable */
import axios, {isCancel, AxiosError} from 'axios';
import {RESERVE_ONLINE_DATA} from './value'
import localOnlineReserveList from './onlineReserve.json'

export async function getReserveList() {
    try {
      const response = await axios.get(RESERVE_ONLINE_DATA);
      console.log('response.data',response.data);
      return response.data
    } catch (error) {
      console.error(error);
      return localOnlineReserveList
    }
}