<template>
  <div v-if="route.name === 'home'" class="foot">
      <div class="logo">
        <div class="name"><img src="@/assets/img/logo.png"/><span>NxFi</span></div>
        <div class="copyright">@2024 NxFi. All rights reserved</div>
      </div>
      <div class="menu">
        <div class="sub-menu">
          <div class="title">Connect</div>
          <div class="item-wrapper">
            <div class="item" @click="open('https://twitter.com/nxfi_protocol')">X</div>
            <div class="item" @click="open('https://discord.com/invite/XJYuQYQzFK')" >Discord</div>
            <div class="item" @click="open('mailto:team@nxdefi.xyz?')" >Contact</div>
          </div>
        </div>
        <div class="sub-menu">
          <div class="title">Products</div>
          <div class="item-wrapper">
            <div class="item" @click="goPage('app')">Lending</div>
            <div class="item" @click="goPage('rwa')">RWA</div>
            <div class="item disable">Points</div>
            <div class="item disable">Profit</div>
          </div>
        </div>
        <div class="sub-menu">
          <div class="title">About</div>
          <div class="item-wrapper">
            <div class="item" @click="open('https://mirror.xyz/0xF09b01132A3f6FcF89e435fA4830719813650bf2')">Blog</div>
            <div class="item" @click="open('https://nxdefi.gitbook.io/nxfi/nxfi')">Documentation</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()
const goPage = ($path) => {
  router.push({
    path: `/${$path}`
  })
}
const open = ($url) => {
  window.open($url)
}
</script>
<style scoped lang="scss">
.foot {
  max-width: 1280px;
  margin: 198px auto 0px;
  padding: 0 20px 150px;
display: flex;
.logo {
  text-align: left;
  width: 50%;
  .name{
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    img {
      height: 55px;
      margin-right: 10px;
    }
  }
  .copyright {
    color: #FFF;
    text-align: left;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.menu {
  flex: 1;
  display: flex;
  flex-direction: row;
  .sub-menu {
    text-align: left;
    width: 25%;
    min-width:80px;
    .title {
      color: rgba(255,255,255,0.5);
      text-align: left;
      font-family: Inter,sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .item-wrapper {
      .disable {
        opacity: 0.6;
      }
      .item {
        cursor: pointer;
        text-align: left;
        color: #FFF;
        font-family: Inter,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top:10px;
      }
    }
  }
}
}
@media (max-width: 800px){
.foot {
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
  .logo {
    text-align: left;
    margin-right: 0px;
    .name{
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 10px;
    }
    .copyright {
      color: #FFF;
      text-align: left;
      font-family: Inter,sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .menu {
    margin-bottom:20px;
    flex-wrap:wrap;
    .sub-menu {
      width:33%;
      text-align: left;
      .title {
        color: rgba(255,255,255,0.5);
        text-align: left;
        font-family: Inter,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .item-wrapper {
        .item {
          cursor: pointer;
          text-align: left;
          color: #FFF;
          font-family: Inter,sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top:5px;
        }
      }
    }
  }
}
}
</style>
